<template>
  <div class="dialogwrapper" v-if="show">
    <div class="overlay"></div>
    <div class="dialog">
      <div class="h5">{{title}}</div>
      <div>{{text}}</div>
      <div class="btns">
        <b-button @click="cancel">取消</b-button>
        <b-button @click="ok" variant="success">确定</b-button>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      text:'',
      title:'',
      promiseStatus: null,
      show: false,
    }
  },
  methods: {
    confirm(option ={title:'提示',text:'确认提交签署吗？'}) {
      this.title = option.title
      this.text = option.text
      // console.log('执行啊了啊 ')
      let _this = this
      this.show = true
      return new Promise(function (resolve, reject) {
        _this.promiseStatus = { resolve, reject }
      })
    },
    ok() {
      // console.log('确认')
      this.show = false
      this.promiseStatus && this.promiseStatus.resolve()
    },
    cancel() {
      // console.log('取消')
      this.show = false
      this.promiseStatus && this.promiseStatus.reject()
    },
  },
}
</script>
<style scoped>
.dialogwrapper {
  z-index: 9999 !important;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  pointer-events: none;
  position: fixed;
  top: 0px;
  width: 100%;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1) 0s, z-index 1ms ease 0s;
  outline: none;
}

.dialog {
  overflow-y: auto;
  pointer-events: auto;
  z-index: inherit;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  border-radius: 4px;
  margin: 24px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  min-width: 250px;
  background: #fff;
  padding: 10px;
  transform: rotate(90deg);
}
.overlay {
  align-items: center;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  background: #000;
  opacity: 0.46;
}
.btns{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
</style>